import * as React from 'react';
import { useState } from 'react';
import { Outlet } from "react-router-dom";
import { Box, Button, TextField } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import SalesTable from "../components/SalesTable";

const Search = (props) => {
  const [open, setOpenAlert] = React.useState(false);
  var [sales, setSales] = useState([]);
  var [newTSNumber, setNewTSNumber] = useState('');
  var [newAddress, setNewAddress] = useState('');
  var [alertMessage, setAlertMessage] = useState('');

  const handleUpdateTSNumber = (event) => {
    setNewTSNumber(event.target.value)
  }

  const handleUpdateAddress = (event) => {
    setNewAddress(event.target.value)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  }

  const submitTSHandler = (event) => {
    event.preventDefault();
    setSales([])
    fetch("/api/sales/tsnumber?tsNumber="+newTSNumber, {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'Hostname': window.location.hostname
      },
      referrerPolicy: 'no-referrer',
    })
    .then(r=>r.json())
    .then(res => {
      if (res.info.length === 0) {
        setAlertMessage(`No result found for TS Number ${newTSNumber}`)
        setOpenAlert(true)
      } else {
        setSales(res.info)
      }
    })
  }

  const submitAddressHandler = (event) => {
    event.preventDefault();
    setSales([])
    fetch("/api/sales/address?address="+newAddress, {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      referrerPolicy: 'no-referrer',
    })
    .then(r=>r.json())
    .then(res => {
      if (res.info.length === 0) {
        setAlertMessage(`No result found for address ${newAddress}`)
        setOpenAlert(true)
      } else {
        setSales(res.info)
      }
    })
  }

  return (
    <>
      <h1>Trustee Sales Search</h1>
      <h3>
      Search for Trustee Sales by Trustee Sale number or property address.
      </h3>
      <h3>
      Use the text box below to search our website. You may also call our phone number at <a href="tel:9497764697">(949) 776-4697</a>. You must have the numerical portion of the trustee sale number to search our sales line.
      </h3>

      <h3>Note that leaving either search box empty and clicking search will list all sales.</h3>
      <div>
        <Box >
          <form onSubmit={submitTSHandler}>
            <TextField onChange={handleUpdateTSNumber} id="outlined-basic" label="Case Ref #" variant="outlined" />
            <Button name="ts-search" type="submit">Case Ref Search</Button>
          </form>
          <form onSubmit={submitAddressHandler}>
            <TextField onChange={handleUpdateAddress} id="outlined-basic" label="Address" variant="outlined" />
            <Button name="address-search" type="submit">Address Search</Button>
          </form>
        </Box>
      </div>
        <br></br>
        <SalesTable sales={sales}></SalesTable>
        <br></br>
      <div>
        <em>
          Disclaimer: Prestige Posting and Publishing (“PPP”) makes no guarantees, representations or warranties as to the accuracy of the information being provided. PPP provides this information as a courtesy and assumes no responsibility for the sale information provided on this site. PPP further advises you to seek legal counsel to thoroughly understand the risks of foreclosure sale transactions.
        </em>
      </div>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert
          severity="warning"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    <Outlet />
  </>
  );
}

export default Search;
