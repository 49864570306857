import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const SalesTable = (props) => {
    return (
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell><strong>Case Reference Number</strong></TableCell>
                    <TableCell align="left"><strong>Property Address</strong></TableCell>
                    <TableCell align="left"><strong>Original Sale Date</strong></TableCell>
                    <TableCell align="left"><strong>Sale Date</strong></TableCell>
                    <TableCell align="left"><strong>Sale Time</strong></TableCell>
                    <TableCell align="left"><strong>Sale Status</strong></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            {props.sales.map((row) => (
                <TableRow
                    key={row.tsNumber}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell align="left">{row.caseRefNumber}</TableCell>
                    <TableCell align="left">{row.propertyAddress}</TableCell>
                    <TableCell align="left">{row.originalSaleDate}</TableCell>
                    <TableCell align="left">{row.saleDate}</TableCell>
                    <TableCell align="left">{row.saleTime}</TableCell>
                    <TableCell align="left">{row.saleStatus}</TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
        </TableContainer>
    );
}

export default SalesTable;
